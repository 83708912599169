/* button themes on item level */
.button--theme-color-01:not(.link-list__list) {
    background: #f2f2f2 !important;
    color: #000 !important;
}
.button--theme-color-01.link-list__list a {
    background: #f2f2f2 !important;
    color: #000 !important;
}
.button--theme-color-01:not(.link-list__list):hover,
.button--theme-color-01:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-01.link-list__list a:hover,
.button--theme-color-01.link-list__list a:focus {
    background: #F3C300 !important;
}

.button--theme-color-02:not(.link-list__list) {
    background: #4b4c4c !important;
    color: #fff !important;
}
.button--theme-color-02.link-list__list a {
    background: #4b4c4c !important;
    color: #fff !important;
}
.button--theme-color-02:not(.link-list__list):hover,
.button--theme-color-02:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-02.link-list__list a:hover,
.button--theme-color-02.link-list__list a:focus {
    background: #F3C300 !important;
}

.button--theme-color-03:not(.link-list__list) {
    background: #0a1980 !important;
    color: #fff !important;
}
.button--theme-color-03.link-list__list a {
    background: #0a1980 !important;
    color: #fff !important;
}
.button--theme-color-03:not(.link-list__list):hover,
.button--theme-color-03:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-03.link-list__list a:hover,
.button--theme-color-03.link-list__list a:focus {
    background: #F3C300 !important;
}

.button--theme-color-04:not(.link-list__list) {
    background: #b6b8dd !important;
    color: #000 !important;
}
.button--theme-color-04.link-list__list a {
    background: #b6b8dd !important;
    color: #000 !important;
}
.button--theme-color-04:not(.link-list__list):hover,
.button--theme-color-04:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-04.link-list__list a:hover,
.button--theme-color-04.link-list__list a:focus {
    background: #F3C300 !important;
}

.button--theme-color-05:not(.link-list__list) {
    background: #ccdce7 !important;
    color: #000 !important;
}
.button--theme-color-05.link-list__list a {
    background: #ccdce7 !important;
    color: #000 !important;
}
.button--theme-color-05:not(.link-list__list):hover,
.button--theme-color-05:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-05.link-list__list a:hover,
.button--theme-color-05.link-list__list a:focus {
    background: #F3C300 !important;
}

.button--theme-color-06:not(.link-list__list) {
    background: #005288 !important;
    color: #fff !important;
}
.button--theme-color-06.link-list__list a {
    background: #005288 !important;
    color: #fff !important;
}
.button--theme-color-06:not(.link-list__list):hover,
.button--theme-color-06:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-06.link-list__list a:hover,
.button--theme-color-06.link-list__list a:focus {
    background: #F3C300 !important;
}

.button--theme-color-07:not(.link-list__list) {
    background: #f8df8d !important;
    color: #000 !important;
}
.button--theme-color-07.link-list__list a {
    background: #f8df8d !important;
    color: #000 !important;
}
.button--theme-color-07:not(.link-list__list):hover,
.button--theme-color-07:not(.link-list__list):focus {
    background: #F3C300 !important;
    color: #000 !important;
}
.button--theme-color-07.link-list__list a:hover,
.button--theme-color-07.link-list__list a:focus {
    background: #F3C300 !important;
    color: #000 !important;
}

.button--theme-color-08:not(.link-list__list) {
    background: #f2f2f2 !important;
    color: #000 !important;
}
.button--theme-color-08.link-list__list a {
    background: #f2f2f2 !important;
    color: #000 !important;
}
.button--theme-color-08:not(.link-list__list):hover,
.button--theme-color-08:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-08.link-list__list a:hover,
.button--theme-color-08.link-list__list a:focus {
    background: #F3C300 !important;
}

.button--theme-color-09:not(.link-list__list) {
    background: #1226AA !important;
    color: #fff !important;
}
.button--theme-color-09.link-list__list a {
    background: #1226AA !important;
    color: #fff !important;
}
.button--theme-color-09:not(.link-list__list):hover,
.button--theme-color-09:not(.link-list__list):focus {
    background: #F3C300 !important;
}
.button--theme-color-09.link-list__list a:hover,
.button--theme-color-09.link-list__list a:focus {
    background: #F3C300 !important;
}
