@import "../../../../Foundation/Styles/Globals/_variables.scss";

span.get-info {
    font-size: 14px;
    margin-left: 12px;
    font-weight: $light;
    cursor: pointer;

    &:before {
        content: url('../../Images/Base/dist/icon-info.svg');
        position: relative;
        top: 2px;
        width: 16px;
        display: inline-block;
        margin-right: 5px;
    }
}

font.get-info {
    font-size: 14px;
    margin-left: 12px;
    font-weight: $light;
    cursor: pointer;

    &:before {
        content: url('../../Images/Base/dist/icon-info.svg');
        position: relative;
        top: 2px;
        width: 16px;
        display: inline-block;
        margin-right: 5px;
    }
}

@import "../../../../Foundation/Content/code/Views/Foundation/Content/CallToAction/_buttons.scss";